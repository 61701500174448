import { useParams } from "react-router-dom";
import KnowledgeGraph from "../../components/Network";

const NSCLCKnowledgeGraph = () => {
    const { id, title, assetClass } = useParams();

    return (
        <KnowledgeGraph type="NSCLC" title={title} id={id} assetClass={assetClass} />
    );
};

export default NSCLCKnowledgeGraph;
