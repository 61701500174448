import { keys } from "lodash";
import { Table } from "antd";

const ChildNodesTable = ({ data }) => {
    const columns = keys(data?.[0]).filter(prop => prop !== "title").map(prop => ({
        title: prop,
        dataIndex: prop,
        key: prop
    }));

    return (
        data?.length > 0 && (<Table dataSource={data} columns={columns} />)
    );
};

export default ChildNodesTable;
