import styled from "styled-components";

export const SegmentedButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #8A0051;
    border-color: #8A0051;
    &:focus-within {
      box-shadow: none;
    }
  } 

  .title{
    margin-bottom: 5px;
  }
  
  .all-btn{
    font-weight: bold;
    color: #666666;
  }

  @media screen and (max-width: 1180px) {
    .ant-radio-button-wrapper {
      font-size: 10px;
      min-width: unset;
      padding: 5px 8px;
    }
  }
`;
