import React from "react";
import { FooterWrapper } from "./Footer.styles";
import { Footer } from "antd/lib/layout/layout";
import AZLOGO from "../../img/logo-az-white.png";
const AZFooter = () => (
  <FooterWrapper>
    <Footer className="footer">
      <span><img className="brandLogo" width={120} src={AZLOGO} alt="" /> </span>
      <span>© Astrazeneca 2022</span>
    </Footer>
  </FooterWrapper>
);

export default AZFooter;
