import styled from "styled-components";

export const ChartWrapper = styled.div`
    width: 100%;
    height: 500px;
`;

export const NetworkGraphContainer = styled.div`
    .flex-right {
        display: flex;
        justify-content: flex-end;
        padding: 8px 0;
    }

    .drug-loading {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        align-items: center;
        display: flex;
        justify-content: center;
        background: #ddd;
        opacity: 0.7;
        z-index: 99;
        position: fixed;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: solid 1px #d9d9d9;
    background: #f1efeffa;
    
    h2 {
        margin-bottom: 0;
    }
`;
