import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Home from "../containers/Home";
import NSCLCKnowledgeGraph from "../containers/NSCLCKnowledgeGraph";
import BreastCancerKnowledgeGraph from "../containers/BreastCancerKnowledgeGraph";
import KnowledgeGraphList from "../components/KnowledgeGraphList";
import KnowledgeGraph from "../components/KnowledgeGraph";
import AuthRoute from "./AuthRoute";

const AppRoutes = ({ element }) => {
    return (
        <Router>
            <Routes>
                <Route element={<AuthRoute>{element}</AuthRoute>}>
                    <Route index element={<Home />} />
                    <Route path="/NSCLC" element={<KnowledgeGraphList type="NSCLC" />} />
                    <Route path="/NSCLC/:assetClass/:id/:title" element={<NSCLCKnowledgeGraph />} />
                    <Route path="/BC" element={<KnowledgeGraphList type="BC" />} />
                    <Route path="/BC/:assetClass/:id/:title" element={<BreastCancerKnowledgeGraph />} />
                    <Route path="/kg" element={<KnowledgeGraph />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default AppRoutes;
