import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Network } from "vis-network";
import { DataSet } from "vis-data";
import ChildNodesTable from "./ChildNodesTable";
import { ChartWrapper, KnowledgeGraphContainer } from "./KnowledgeGraph.styles";
import graphOptions from "./graph-config";
import allNodes from "./graph_nodes.json";
import allEdges from "./graph_edges.json";

const htmlTitle = (node) => {
  const container = document.createElement("div");
  container.style.maxWidth = "500px";
  const props = Object.keys(node);
  container.innerHTML = props.map(prop => (prop === 'title' ? '' :
    `
    <div>
      <strong style="text-transform: capitalize;">${prop}</strong>: <span>${node[prop]}</span>
    </div>
  `)).join('');
  return container;
};

const rootNode = allNodes.find(node => !node.pid);
console.log("rootNode", rootNode);
const firstLevelNodes = allNodes.filter(node => node.pid == rootNode.id);
const firstLevelEdges = allEdges.filter(edge => edge.from == rootNode.id);


const KnowledgeGraph = ({ type = 'NSCLC', title = 'Drug' }) => {
  const [selectedNodeChilds, setSelectedNodeChilds] = useState([]);
  let nodes = new DataSet([rootNode, ...firstLevelNodes]);
  let edges = new DataSet(firstLevelEdges);

  useEffect(() => {
    const data = { nodes, edges };
    const container = document.getElementById("chartdiv");
    const network = new Network(container, data, graphOptions);

    nodes.forEach(node => {
      node.title = htmlTitle(node);
      node.value = node.subids?.length || 0;
    });

    network.on("click", (params) => {
      const selectedNodeEdges = params.edges.map(id => edges._data.get(id));
      const childNodes = selectedNodeEdges.map(edge => allNodes.find(node => node.id === edge.to));

      setSelectedNodeChilds(childNodes);

      if (params.nodes.length != 0) {
        var click_node_id = params.nodes[0];
        remove_all_sub_nodes(click_node_id, network);
      }
    });
  }, []);


  function getNode(option) {
    for (var i = 0; i < allNodes.length; i++) {
      if (option == allNodes[i].id) {
        return allNodes[i];
      }
    }
  }

  function getEdge(item) {
    const edgesData = edges.get();
    const edge = edgesData.find(({ to }) => to === item);

    return edge;
  }

  function remove_all_sub_nodes(node_id, network) {
    var sub_nodes = get_directly_sub_nodes(node_id, network);

    if (sub_nodes.length == 0) {//The currently clicked is the leaf node
      //Determine whether there are subordinate nodes
      const index = allNodes.findIndex(node => node.id === node_id);

      if (typeof (allNodes[index]['subids']) != 'undefined') {
        (allNodes[index]['subids'])?.forEach(function (item) {
          const subNode = allNodes.find(node => node.id === item);

          if (subNode) {
            subNode.title = htmlTitle(subNode);
            subNode.value = subNode.subids?.length || 0;
            nodes.add(subNode);
            edges.add({ from: node_id, to: item });
          }
        });
      } else {
        console.log('Currently a leaf node');
      }
    } else {
      sub_nodes.forEach(function (item) {
        const sub_sub_nodes = get_directly_sub_nodes(item, network);
        const edge = getEdge(item);

        if (sub_sub_nodes.length == 0) {
          edge && edges.remove({ id: edge.id });
          nodes.remove({ id: item });
        } else {
          remove_all_sub_nodes(item, network);
        }

        edge && edges.remove({ id: edge.id });
        nodes.remove({ id: item });
      });
    }
  }

  //todo gets the direct subordinate nodes of a node
  function get_directly_sub_nodes(node_id, network) {
    var return_nodes = [];
    var connectedNodes = network.getConnectedNodes(node_id).length > 1 && (getNode(node_id))?.subids || [];//Get all connected nodes

    connectedNodes.forEach(function (item) {
      if (item != nodes[node_id]?.['pid']) {//The parent node of the current node, do not operate
        return_nodes.push(item);
      }
    });

    return return_nodes;
  }

  return (
    <KnowledgeGraphContainer>
      <NavLink to={`/${type}`}>Back</NavLink>
      <h2>{title}</h2>
      <ChartWrapper id="chartdiv"></ChartWrapper>
      <ChildNodesTable data={selectedNodeChilds} />
    </KnowledgeGraphContainer>
  );
};

export default KnowledgeGraph;
