import { useEffect, useState } from "react";
import { checkAuth, getCookie } from "../utils/cookie-helper";

const useAuth = () => {
    const [username, setUserName] = useState("");

    useEffect(() => {
        checkAuth();
        const user = getCookie("userName");
        if (user) {
            setUserName(user);
        }
    }, []);

    return username;
}

export default useAuth;
