import React from "react";
import { SegmentedButtonWrapper } from "./SegmentedButton.styles";
import { Radio } from "antd";

const SegmentedButton = (props) => {
  const { title, data, onChange, selectedValue } = props;

  return (
    <SegmentedButtonWrapper>
      <div className="title">{title}</div>
      <Radio.Group
        value={selectedValue ? selectedValue : data.length > 0 ? data[0] : ""}
        onChange={onChange}
        buttonStyle="solid"
      >
        {data.map((item, idx) => (
          <Radio.Button
            key={idx}
            value={item}
          >
            {item.name}
          </Radio.Button>
        ))}
      </Radio.Group>
    </SegmentedButtonWrapper>
  );
};

export default SegmentedButton;
