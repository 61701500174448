import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appDefaults from "../constants";
import { GetAPIRequest } from "../services/api";

const initialState = {
    details: {},
    data: { loading: false, data: [], error: false },
    newSiteDetails: {},
    newSites: { loading: false, data: [], error: false },
    azStudies: { loading: false, data: [], error: false },
};

export const fetchSites = createAsyncThunk(
    'site/fetchSites',
    async () => {
        const response = await GetAPIRequest({ url: appDefaults.api.getSites })
        return { loading: false, data: response, error: false }
    }
);

export const fetchNewSites = createAsyncThunk(
    'site/fetchNewSites',
    async () => {
        const response = await GetAPIRequest({ url: appDefaults.api.getNewSites })
        return { loading: false, data: response, error: false }
    }
);

export const fetchAZStudies = createAsyncThunk(
    'site/fetchAZStudies',
    async () => {
        const response = await GetAPIRequest({ url: appDefaults.api.getAZStudies })
        return { loading: false, data: response, error: false }
    }
);



export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        selectSite: (state, action) => {
            state.details = action.payload;
        },
        selectNewSite: (state, action) => {
            state.newSiteDetails = action.payload;
        }
    },
    extraReducers: {
        [fetchSites.pending]: (state) => {
            state.data = { loading: true, data: [], error: false }
        },
        [fetchSites.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [fetchSites.rejected]: (state) => {
            state.data = { loading: false, data: [], error: true }
        },
        [fetchNewSites.pending]: (state) => {
            state.newSites = { loading: true, data: [], error: false }
        },
        [fetchNewSites.fulfilled]: (state, action) => {
            state.newSites = action.payload;
        },
        [fetchNewSites.rejected]: (state) => {
            state.newSites = { loading: false, data: [], error: true }
        },
        [fetchAZStudies.pending]: (state) => {
            state.azStudies = { loading: true, data: [], error: false }
        },
        [fetchAZStudies.fulfilled]: (state, action) => {
            state.azStudies = action.payload;
        },
        [fetchAZStudies.rejected]: (state) => {
            state.azStudies = { loading: false, data: [], error: true }
        }
    }
});

export const { selectSite, selectNewSite } = siteSlice.actions;

export const getSiteDetails = (state) => state.site.details;
export const getSites = (state) => state.site.data;
export const getNewSiteDetails = (state) => state.site.newSiteDetails;
export const getNewSites = (state) => state.site.newSites;
export const getAZStudies = (state) => state.site.azStudies.data;

export default siteSlice.reducer;
