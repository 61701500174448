import styled from "styled-components";

export const HeaderWrapper = styled.div`
  .header {
    display: flex;
    background-color: #ffffff;
    border-bottom: 1px solid #0000000A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 2px 6px #0000000A;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .ant-layout-header {
    line-height: unset;
  }
`;
