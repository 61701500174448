import { configureStore } from '@reduxjs/toolkit';
import siteReducer from './siteSlice';
import keeReducer from './keeSlice';

export const store = configureStore({
  reducer: {
    site: siteReducer,
    kee: keeReducer,
  },
});
