const graphOptions = {
    nodes: {
        shape: "dot",
        size: 16,
        color: {
            background: "#0085AF",
            border: "#013848",
            highlight: "#FF8000"
        },
        shadow: {
            enabled: true,
            size: 10
        }
    },
    edges: {
        shadow: false,
        color: {
            color: "#0085AF",
            highlight: "#C62F4B"
        }
    },
    physics: {
        forceAtlas2Based: {
            gravitationalConstant: -10,
        },
        solver: "forceAtlas2Based",
        stabilization: { iterations: 100 },
    },
    layout: {
        randomSeed: 20
    },
    interaction: {
        hover: true,
        multiselect: true
    }
};

export default graphOptions;
