import styled from 'styled-components';

export const HomeWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 75vh;
    justify-content: space-evenly;
    align-items: center;

    a {
        font-size: 40px;
        color: #760041;
    }
`;
