import { Header } from "antd/lib/layout/layout";
import React from "react";
import Brand from "../Brand";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./Header.styles";

const AZHeader = () => {
  return (
    <HeaderWrapper>
      <Header className="header">
        <Brand />
        <UserInfo />
      </Header>
    </HeaderWrapper>
  );
};

export default AZHeader;
