import styled from "styled-components";

export const KnowledgeGraphListContainer = styled.div`
    .header-container {
        margin-top: 16px;
    }

    .link {
        color: #383838!important;
    }

    .ant-card-head {
        background: #840151;
        color: #fff;
        border-radius: 8px 8px 0 0;
    }

    .ant-card {
        box-shadow: 0px 5px 3px rgb(0 0 0 / 16%), 
        -5px 3px 10px rgb(0 0 0 / 16%), 5px 3px 10px rgb(0 0 0 / 16%);
        border-radius: 8px;
        margin: 16px;
    }

    .ant-tabs-tab {
        //color: #fff;
        color: #788392;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .ant-col {
        margin: 8px 0;
    }
`;

export const Tile = styled.div`
    border-radius: 8px;
    border: solid 2px #ffad33;
    color: #788392;
    font-size: 18px;
    padding: 16px;
    font-weight: 500;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: solid 1px #d9d9d9;
    background: #f1efeffa;
    
    h2 {
        margin-bottom: 0;
    }
`;