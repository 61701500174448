import React from "react";
import { Link } from "react-router-dom";
import { HomeWrapper} from "./Home.styles";

const Home = () => {
  return (
    <HomeWrapper className="HomeWrapper">
      <Link to="/NSCLC">NSCLC</Link>
      <Link to="/BC">BC</Link>
    </HomeWrapper>
  );
};

export default Home;
