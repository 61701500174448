import { useCallback, useEffect, useState, useRef } from "react";
import { Spin, Skeleton } from "antd";
import { NavLink } from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import SegmentedButton from "../SegmentedButton";
import { ChartWrapper, NetworkGraphContainer, Header } from "./Network.styles";
import appDefaults from "../../constants";
import { GetAPIRequest } from "../../services";

const graphViews = [
    { id: "competitiveIntelligence", name: "Competitive Intelligence" },
    { id: "discovery", name: "Discovery Data" },
    { id: "translationalMedicine", name: "Translational Medicine" },
    { id: "RWE", name: "RWE" }
];

const nodeColors = {
    root: "#67b7dc",
    linker: "#a367dc",
    Oncology: "#dc67ce",
    Sponsers: "#dc8c67",
    Targets: "#dc6788",
    warhead: "#6771dc"
};

const setNodeColor = (node, expandedNodes = []) => {
    if (expandedNodes.includes(node.path)) {
        node.collapsed = false;
    }

    if (nodeColors[node.name] || node.id) {
        node.color = node.id ? nodeColors.root : nodeColors[node.name];
    }

    if (node.children?.length > 0) {
        node.children.forEach(subNode => {
            subNode.path = `${node.path}/${subNode.uid}`;
            setNodeColor(subNode, expandedNodes);
        });
    }
};

const findNode = (node, path) => {
    if (node.path === path) {
        console.log("findNode-id:", node.path);
        return node;
    } else if (node.children?.length > 0) {
        for (let subNode of node.children) {
            const selectedNode = findNode(subNode, path);
            if (selectedNode) {
                return selectedNode;
            }
        }
    }
};

const processData = (data, drugData, nodePath, networkSeries, expandedNodes) => {
    const rootNode = data.competitiveIntelligence[0];
    const selectedNode = findNode(rootNode, nodePath);
    selectedNode.children = drugData.children;
    setNodeColor(rootNode, expandedNodes);
    networkSeries.setData(data.competitiveIntelligence);
}

const DrugNetwork = ({ id, assetClass, type, title }) => {
    const chartRef = useRef(null);
    const networkSeriesRef = useRef(null);
    const drugData = useRef({});
    const expandedNodes = useRef([]);

    const [viewType, setViewType] = useState(graphViews[0]);
    const handleViewChange = (event) => {
        setViewType(event.target.value);
        const graphData = data[event.target.value.id] || [];
        networkSeriesRef.current.data = graphData;
    };

    const [data, setData] = useState({
        competitiveIntelligence: [],
        discovery: [],
        translationalMedicine: [],
        RWE: [],
    });
    const [loading, setLoading] = useState(false);
    const [drugLoading, setDrugLoading] = useState(false);

    const fetchData = useCallback(async (id) => {
        try {
            setLoading(true);
            const url = `${appDefaults.api.getCompetitiveIntelligence}?drugid=${id}&assetclass=${assetClass}&indication=${type}`;
            const response = await GetAPIRequest({ url });
            drugData.current[id]= response?.[0];
            setData({ ...data, competitiveIntelligence: response });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("Error", error);
        }
    }, [assetClass, type]);

    const fetchDrugData = useCallback((nodePath, id, networkSeries) => {
        const url = `${appDefaults.api.getCompetitiveIntelligence}?drugid=${id}&assetclass=${assetClass}&indication=${type}`;

        setDrugLoading(true);
        GetAPIRequest({ url }).then(response => {
            drugData.current[id] = response?.[0];
            processData(data, drugData.current[id], nodePath, networkSeries, expandedNodes.current);
            setDrugLoading(false);
        }).catch(error => {
            console.log("error", error);
            setDrugLoading(false);
        });
    }, [data, assetClass, type]);

    useEffect(() => {
        fetchData(id);
    }, [id]);

    useEffect(() => {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("chartdiv", am4plugins_forceDirected.ForceDirectedTree);

        chartRef.current = chart;
        chart.legend = new am4charts.Legend();
        chart.zoomable = true;

        let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

        networkSeriesRef.current = networkSeries;
        networkSeries.dataFields.linkWith = "linkWith";
        networkSeries.dataFields.name = "name";
        networkSeries.dataFields.value = "value";
        networkSeries.dataFields.color = "color";
        networkSeries.dataFields.children = "children";
        networkSeries.dataFields.collapsed = "collapsed";
        networkSeries.nodes.template.label.text = "{name}"
        networkSeries.manyBodyStrength = -16;
        networkSeries.minRadius = 15;
        networkSeries.maxRadius = 40;
        networkSeries.fontSize = 10;
        networkSeries.nodes.template.expandAll = false;

        let nodeTemplate = networkSeries.nodes.template;

        nodeTemplate.tooltipText = "{name}";
        nodeTemplate.fillOpacity = 2;
        nodeTemplate.label.hideOversized = true;
        nodeTemplate.label.truncate = true;

        let linkTemplate = networkSeries.links.template;

        linkTemplate.strokeWidth = 1;
        linkTemplate.distance = 2;

        let linkHoverState = linkTemplate.states.create("hover");

        linkHoverState.properties.strokeOpacity = 1;
        linkHoverState.properties.strokeWidth = 2;

        var hl = networkSeries.nodes.template.states.create("selected");
        hl.properties.fill = am4core.color(hl.properties.fill).brighten(1);
        hl.properties.fontWeight = 'bold';

        var selectedNode;

        networkSeries.nodes.template.events.on("hit", async function (ev) {
            if (selectedNode === ev.target) {
                selectedNode.fill = selectedNode.defaultState.properties.fill;
                selectedNode.fontWeight = "normal";
                selectedNode = undefined;
            }
            else {
                if (selectedNode) {
                    selectedNode.fill = selectedNode.defaultState.properties.fill;
                    selectedNode.fontWeight = "normal";
                }
                selectedNode = ev.target;
                selectedNode.setState("selected");
            }
        });

        networkSeries.nodes.template.events.on("hit", function (event) {
            const path = event.target.dataItem.dataContext.path;
            const id = event.target.dataItem.dataContext.id;

            if (!expandedNodes.current.includes(path)) {
                expandedNodes.current.push(path);
            }
            else {
                expandedNodes.current.splice(expandedNodes.current.indexOf(path), 1);
            }

            if (id && event.target.dataItem.children?.length === 0) {
                if (!drugData.current[id]) {
                    fetchDrugData(path, id, networkSeries);
                }
            }
        });

        nodeTemplate.events.on("over", function (event) {
            let dataItem = event.target.dataItem;
            dataItem.childLinks.each(function (link) {
                link.isHover = true;
            });
        });

        nodeTemplate.events.on("out", function (event) {
            let dataItem = event.target.dataItem;
            dataItem.childLinks.each(function (link) {
                link.isHover = false;
            });
        });

        nodeTemplate.events.on("ready", function (event) {
            console.log("ready event");
            if (event.target.dataItem.name === data.competitiveIntelligence[0].name) {
                selectedNode = event.target;
                selectedNode.setState("selected");
            }
        });

        if (data.competitiveIntelligence?.length) {
            const rootNode = data.competitiveIntelligence[0];
            rootNode.path = `${rootNode.uid}`;
            setNodeColor(rootNode, []);
        }

        networkSeries.data = data.competitiveIntelligence;

        return () => {
            chart?.dispose();
        };
    }, [data]);

    return (
        <NetworkGraphContainer>
            <Header>
                <NavLink to={`/${type}`}>Back</NavLink>
                <h2>{title}</h2>
                <div></div>
            </Header>
            <Spin className="loading" spinning={loading} />
            <Skeleton loading={loading} active />
            {!loading && (
                <div className="flex-right">
                    <SegmentedButton data={graphViews} selectedValue={viewType} onChange={handleViewChange} />
                </div>
            )}
            <ChartWrapper id="chartdiv"></ChartWrapper>
            {drugLoading && <Spin className="drug-loading" />}
        </NetworkGraphContainer>
    );
};

export default DrugNetwork;