import { useEffect, useState } from "react";
import { Card, Col, Row, Spin, Skeleton } from "antd";
import { NavLink, Link } from "react-router-dom";
import { KnowledgeGraphListContainer, Tile, Header } from "./KnowledgeGraphList.styles";
import appDefaults from "../../constants";
import { GetAPIRequest } from "../../services";

const KnowledgeGraphList = ({ type }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const url = `${appDefaults.api.getDrugsDataByIndication}/${type}`;
                const response = await GetAPIRequest({ url });
                setData(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("Error", error);
            }
        };
        
        fetchData();
    }, [type]);

    return (
        <KnowledgeGraphListContainer>
            <Header>
                <NavLink to={`/`}>Back</NavLink>
                <h2>{type}</h2>
                <div></div>
            </Header>
            <Spin className="loading" spinning={loading} />
            <Skeleton loading={loading} active />
            {!loading && data?.map(drug => (
                <Card key={drug.assetClassName} title={drug.assetClassName}>
                    <Row gutter={16}>
                        {drug.assets?.map(node => (
                            <Col key={node.DrugId} span={4}>
                                <Tile>
                                    <Link className="link" to={`/${type}/${drug.assetClassName}/${node.DrugId}/${node.DrugName}`}>
                                        {node.DrugName}
                                    </Link>
                                </Tile>
                            </Col>
                        ))}
                    </Row>
                </Card>
            ))}
        </KnowledgeGraphListContainer>
    );
};

export default KnowledgeGraphList;