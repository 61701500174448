import axios from "axios";
import { getCookie, redirectToPing } from "../../utils/cookie-helper";

export const client = axios.create({
  withCredentials: false,
});

client.interceptors.request.use(
  function (config) {
    config.headers.common["Content-Type"] = "application/json";
    const access_token = getCookie("access-token");

    if (access_token) {
      config.headers.common["authorization"] = "Bearer " + access_token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const request = function (options) {
  const onSuccess = function (response) {
    console.log("Request Successful!", response);
    return response.data;
  };

  const handleStatusCode = (statuscode) => {
    switch (statuscode) {
      case 200:
        console.log("ajax.statusCode: 200");
        break;
      case 404:
        console.log("ajax.statusCode: 404");
        break;
      case 500:
        console.log("ajax.statusCode: 500");
        break;
      case 403:
        console.log("ajax.statusCode: 403");
        localStorage.setItem("isPrevState", "TRUE");
        redirectToPing();
        break;
      default:
        break;
    }
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      console.error("Data:", error.response.data);
      handleStatusCode(error.response.status);
    } else {
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export const GetAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: url,
    method: "GET",
    ...otherOptions,
  });
};

export const PostAPIRequest = (options) => {
  return request({
    method: "POST",
    ...options,
  });
};

export const PutAPIRequest = (options) => {
  return request({
    method: "PUT",
    ...options,
  });
};

export const DeleteAPIRequest = (options) => {
  return request({
    method: "DELETE",
    ...options,
  });
};

export const MultipleGetRequest = (options) => {
  if (options && options.URL1 && options.URL2) {
    axios
      .all([axios.get(options.URL1), axios.get(options.URL2)])
      .then((responseArr) => {
        console.log("Request Successful!", responseArr);
        return responseArr;
      })
      .catch((error) => {
        console.error("Request Failed:", error.config);
        if (error.response) {
          console.error("Data:", error.response.data);
        } else {
          console.error("Error Message:", error.message);
        }
        return Promise.reject(error.response || error.message);
      });
  }
};

export default request;
