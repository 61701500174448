import { useParams } from "react-router-dom";
import KnowledgeGraph from "../../components/Network";

const BreastCancerKnowledgeGraph = () => {
    const { id, title, assetClass } = useParams();

    return (
        <KnowledgeGraph title={title} type="BC" id={id} assetClass={assetClass} />
    );
};

export default BreastCancerKnowledgeGraph;
