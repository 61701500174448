import React from "react";
import { Avatar } from "antd";
import {
  UserInfoWrapper,
  User,
  UserInfoName
} from "./UserInfo.styles";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getCookie } from "../../utils/cookie-helper";

const UserInfo = () => {
  const [username, setUserName] = useState("");

  useEffect(() => {
    const user = getCookie("userName");
    
    if (user) {
      setUserName(user);
    }
    
  }, []);
  return (
    <UserInfoWrapper>
      <User>
        <UserInfoName>{username}</UserInfoName>
      </User>
      <Avatar className="userInfoIcon" size="large" icon={<UserOutlined />} />
    </UserInfoWrapper>
  );
};

export default UserInfo;
