import styled from "styled-components";

export const KnowledgeGraphContainer = styled.div`
    h2 {
        text-align: center;
    }
    .selectField {
        width: 300px;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`

export const ChartWrapper = styled.div`
    height: 400px;
`;
