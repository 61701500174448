const BASE_URL = process.env.REACT_APP_BASE_URL;
const IDENTITY_BASE_URL = process.env.REACT_APP_IDENTITY_BASE_URL;
const BASE_URL_WITH_PATH = BASE_URL + "/okg/api/v1";
const appDefaults = {
  api: {
    baseURL: BASE_URL,
    identityBaseURL: IDENTITY_BASE_URL,
    auth: `${BASE_URL_WITH_PATH}/identity/azure/login`,
    getDrugsDataByIndication:  `${BASE_URL_WITH_PATH}/assets/az`,
    getCompetitiveIntelligence: `${BASE_URL_WITH_PATH}/kg/competitiveintelligence`,
  },
};

export default appDefaults;
