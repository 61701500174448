import React from "react";
import AZLOGO from "../../img/logo-az-condensed.png";
import { BrandWrapper } from "./Brand.styles";
import { NavLink } from "react-router-dom";

const Brand = () => (
  <BrandWrapper>
    <NavLink to="/">
      <img className="brandLogo" src={AZLOGO} alt="" />
      <span className="title">Oncology Knowledge Graph</span>
    </NavLink>
  </BrandWrapper>
);

export default Brand;
